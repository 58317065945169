import { Avatar, Col, List, Radio, Row, Spin, Typography } from "antd";
import { Chart, registerables } from "chart.js";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import "../assets/css/campaignAnalytics.css";
import InfiniteVirtualTable from "../components/custom/InfiniteVirtualTable";
import { AppContext } from "../context/AppContext";
import useCampaignApi from "../hooks/useCampaign";
import useHttp from "../hooks/useHttp";
import CampaignContent from "./campaign/components/campaign/CampaignContent";
import { getCampaignStatusFromStatusCode } from "./campaign/utils/campaignUtils";
import { failureNotification } from "./utils/CommonNotifications";
import { CountUpStatistic, RefreshButton } from "./utils/CommonVessels";
import { capitalizeFirstLetter } from "./utils/commonUtils";

const { Text, Paragraph } = Typography;
Chart.register(...registerables);

const columns = [
  {
    title: "Name",
    dataIndex: "receiver",
    key: "receiver",
    className: "p-auto",
    width: 270,
    ellipsis: true,
    render: (receiver, item) => {
      return (
        <div className="h-100 d-flex align-items-center">
          <div className="ms-lg-3 me-2 hs-bg-light-orange rounded-circle d-flex align-items-center justify-content-center list-row-avatar">
            {item?.receiverPhoto ? (
              <Avatar rootClassName="w-100 h-100" src={item?.receiverPhoto} />
            ) : item?.receiverName ? (
              <span className="hs-color-dark-orange hs-fw-600 hs-fs-18">{item?.receiverName?.slice(0, 1)?.toUpperCase()}</span>
            ) : (
              <div className="h-100 w-100 avatarIcon rounded-circle staticIconsSprite" />
            )}
          </div>
          {item?.receiverName && (
            <>
              <span className="mx-1">{capitalizeFirstLetter(item?.receiverName)}</span>
              <span className="mx-1">{`-`}</span>
            </>
          )}
          <span className="mx-1">{receiver}</span>
        </div>
      );
    },
  },
  {
    title: "Date Sent",
    dataIndex: "createdTime",
    key: "createdTime",
    className: "p-auto",
    width: 200,
    render: (text) => {
      return (
        <div className="d-flex h-100 align-items-center">
          <Text ellipsis={{ tooltip: { text } }}>{text}</Text>
        </div>
      );
    },
  },
  {
    title: "Error Code",
    key: "errorCode",
    dataIndex: "errorCode",
    className: "p-auto",
    width: 500,
    render: (errorCode, item) => {
      let { statusDetails } = item;
      statusDetails = statusDetails ?? "Something went wrong ;(";
      return (
        <div className="d-flex align-items-center h-100">
          {errorCode ? `${errorCode} : ` : ""}
          {statusDetails}
        </div>
      );
    },
  },
];

const campaignReportCards = [
  {
    img: "totalProcessed",
    title: "Total Planned",
    id: "totalProcessed",
  },
  {
    img: "inProgress",
    title: "In Progress",
    id: "inProgress",
  },
  {
    img: "sent",
    title: "Awaiting Status",
    id: "sent",
  },
  {
    img: "success",
    title: "Delivered",
    id: "success",
  },
  {
    img: "failure",
    id: "failure",
    title: "Failure",
  },
  {
    id: "no_phone_number",
    img: "skipped",
    title: "Skipped",
  },
];

const options = {
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {},
    },
  },
  plugins: {
    legend: { display: false },
    tooltip: {
      borderColor: "black",
      displayColors: false,
    },
  },
};

function CampaignAnalytics(props) {
  const { currentItem } = props;
  const [currentMenuItem, setCurrentMenuItem] = useState(currentItem);
  const [appProperties, setAppProperties] = useContext(AppContext);
  const { getCampaignByCampaignId, getCampaignOverview } = useCampaignApi(appProperties);
  const [campaignInfo, setCampaignInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const { fetchData } = useHttp();
  const [graphData, setGraphData] = useState([]);
  const [campaignOverview, setCampaignOverview] = useState();
  const location = useLocation();
  const paginationRef = useRef(null);
  const [tableData, setTableData] = useState();
  const pathSegments = location.pathname.split("/");
  const campaignIdFromPath = pathSegments[3];
  const [campaignId] = useState(campaignIdFromPath);

  const fetchAPi = useCallback(async () => {
    if (appProperties?.serviceInstalled) {
      try {
        handleLoading(true);
        appProperties?.setLoading?.(true);
        let response = await getCampaignByCampaignId(campaignId);
        let responseData = response?.data;
        let initialValues = {
          campaignStatus: responseData?.campaign?.campaignStatus,
          disabled: true,
          campaign: responseData?.campaign,
          source: "analytics",
          campaignName: responseData?.campaign?.campaignName,
          campaignStatusName: getCampaignStatusFromStatusCode(responseData?.campaign?.campaignStatus),
          campaignId: campaignId,
          fromNumber: responseData?.campaign?.sender,
          moduleName: responseData?.campaign?.moduleName,
          moduleOptions: responseData?.modules,
          filterName: responseData?.campaign?.filterName,
          messageComponents: responseData?.messageComponents,
          filterOptions: responseData?.filters,
          filterId:
            responseData?.campaign?.campaignStatus === 53 && (responseData?.campaign?.selectedIds || responseData?.campaign?.excludedIds)
              ? null
              : responseData?.campaign?.filterId,
          csvFileName: responseData?.campaign?.csvFileName,
          phoneFieldApi: responseData?.campaign?.phoneFieldApi,
          selectedIds: responseData?.campaign?.selectedIds,
          excludedIds: responseData?.campaign?.excludedIds,
          csvFileId: responseData?.campaign?.csvFileId,
          sharedFilter: responseData?.sharedFilter ?? false,
          testTo: "",
          message: responseData?.campaign?.messageBody,
          date: dayjs(responseData?.campaign?.scheduledTime ?? responseData?.campaign?.createdTime),
          time: dayjs(responseData?.campaign?.scheduledTime ?? responseData?.campaign?.createdTime),
          type: responseData?.campaign?.scheduledCampaign === 0 ? "immediate" : "scheduled",
          mediaFileList:
            responseData?.campaign?.mediaFileList &&
            responseData?.campaign?.mediaFileList.map((item) => {
              const parsedItem = JSON.parse(item);
              parsedItem.status = "done";
              parsedItem.type = parsedItem?.contentType;
              parsedItem.disabled = true;
              return parsedItem;
            }),
          phoneField: responseData?.campaign?.phoneField,
          moduleFields: responseData?.campaign?.moduleFields,
        };
        if (responseData?.campaign?.campaignName) {
          setAppProperties((prev) => ({
            ...prev,
            headerTitleName: responseData?.campaign?.campaignName,
          }));
        }
        setCampaignOverview(initialValues);
        if (responseData?.campaign?.recordsObj) {
          let recordsObj = JSON.parse(responseData?.campaign?.recordsObj);
          initialValues.recordsObj = recordsObj;
        }
        appProperties.setLoading(false);
      } catch {
        failureNotification("Error in fetching campaign information");
      } finally {
        handleLoading(false);
      }
    }
  }, [campaignId, fetchData, appProperties?.serviceInstalled]);

  useEffect(() => {
    if (appProperties.serviceInstalled) {
      fetchAPi();
    }
  }, [fetchAPi, appProperties.serviceInstalled]);

  const handleMenuClick = (e) => {
    setCurrentMenuItem(e.target.value);
  };

  const handleLoading = (loadingState) => {
    setLoading(loadingState);
  };

  const handleScrollApi = useCallback(async () => {
    handleLoading(true);
    if (!loading) {
      try {
        const response = await getCampaignOverview(campaignId, paginationRef);
        if (response) {
          const campaignStats = getCampaignStatsFromResponse(response?.data?.campaign);
          response.data.campaign = campaignStats;
          setCampaignInfo(response.data);
          response.data.metrics = response.data.metrics.sort((a, b) => b.count - a.count);
          const metricData = response.data.metrics.map((obj) => {
            const { color, fillArea } = getRandomColor();
            return {
              ...obj,
              label: `Error code ${obj.code}`,
              data: [0, 0, 0, 0, obj.count < 9999 ? `${obj.count}` : `9999+`],
              fill: true,
              backgroundColor: fillArea,
              borderColor: color,
              lineTension: 0.6,
            };
          });
          setGraphData(metricData);

          if (response.data?.messages?.length > 0) {
            let updatedData = response?.data?.messages.map((obj) => {
              return {
                ...obj,
                campaignName: response?.data?.campaign?.campaignName,
                createdTime: dayjs(obj.createdTime).format("YYYY-MM-DD HH:mm A"),
              };
            });
            setTableData(updatedData);
            if (response.data?.pagination) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      } catch (e) {
        failureNotification("Error getting campaign metrics");
        return true;
      } finally {
        handleLoading(false);
      }
    }
  }, [fetchData, appProperties, campaignId]);

  const getCampaignStatsFromResponse = (campaign) => {
    if (campaign) {
      const noPhoneNumber = campaign.no_phone_number ?? 0;
      const failure = campaign?.failure ?? 0;
      const totalProcessed = campaign?.totalProcessed ?? 0;
      const sent = campaign?.sent ?? 0;
      const success = campaign?.success ?? 0;
      const inProgress = campaign?.inProgress ?? 0;

      campaign.sent = sent;
      campaign.no_phone_number = noPhoneNumber;
      campaign.totalProcessed = totalProcessed;
      campaign.failure = failure;
      campaign.success = success;
      campaign.inProgress = inProgress;
    }
    return campaign;
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="d-flex align-items-center m-3 gap-2">
          <Radio.Group borderColorDisabled className="campaign-menu" style={{ border: 0 }} bordered={false} onChange={handleMenuClick}>
            <Radio.Button
              className={`campaign-menu-button hs-border-10  ${currentMenuItem === "overview" ? "overview-btn" : "anti-hover"}`}
              value="overview"
              bordered={false}
            >
              Overview
            </Radio.Button>
            <Radio.Button
              className={`campaign-menu-button hs-border-10  ${currentMenuItem === "error" ? "overview-btn" : "anti-hover"}`}
              value="error"
            >
              Reports
            </Radio.Button>
          </Radio.Group>
          {currentMenuItem === "error" && <RefreshButton loading={loading} onClickFunction={handleScrollApi} />}
        </div>
        {currentMenuItem === "overview" ? (
          campaignOverview ? (
            <CampaignContent
              appProperties={appProperties}
              initialValues={campaignOverview}
              setInitialValues={setCampaignOverview}
              setAppProperties={setAppProperties}
              showOnlyStop={campaignOverview?.selectedIds || campaignOverview?.excludedIds}
            />
          ) : (
            <></>
          )
        ) : (
          <CampaignInfo
            graphData={graphData}
            campaignInfo={campaignInfo}
            handleScrollApi={handleScrollApi}
            tableData={tableData}
            appProperties={appProperties}
          />
        )}
      </Spin>
    </>
  );
}
const CampaignInfo = React.memo((props) => {
  const { campaignInfo, graphData, handleScrollApi, appProperties, tableData } = props;
  return (
    <div className="d-flex justify-content-center">
      <div className="d-flex flex-column align-items-center hs-w-95 mt-3">
        <div className="d-flex justify-content-center mt-2 w-100 bg-white hs-border-12">
          <Row className="d-flex justify-content-between hs-w-90-per bg-white">
            {campaignReportCards.map((obj, index) => (
              <React.Fragment key={index}>
                <Col md={4} xs={12} className="w-100 bg-white justify-content-center d-flex align-items-center" style={{ height: "120px" }}>
                  <div className="card-analytics w-100">
                    {index > 0 && <div className="vr mx-2 bs-vr-override h-50 my-auto hs-color-light-blue-2 d-lg-block d-none" />}
                    <div className="row w-100">
                      <div className="col-6 d-flex align-items-center">
                        <div className={`campaign-analytic-sprites ci-${obj.img}`} />
                        <span style={{ whiteSpace: "nowrap" }}>{obj?.title}</span>
                      </div>
                      <div className="col-12 ms-4 ps-4">
                        <CountUpStatistic
                          duration={5}
                          finalCount={campaignInfo?.campaign?.[obj?.id] < 999999 ? `${campaignInfo?.campaign?.[obj?.id]}` : `999999+`}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </React.Fragment>
            ))}
          </Row>
        </div>
        <GraphRow graphData={graphData} createdTime={campaignInfo?.campaign?.createdTime} campaignMetrics={campaignInfo?.metrics} />
        <CampaignErrors handleScrollApi={handleScrollApi} tableData={tableData} appProperties={appProperties} />
      </div>
    </div>
  );
});

const GraphRow = React.memo((props) => {
  const { campaignMetrics, createdTime, graphData } = props;
  const [yAxisData, setYAxisData] = useState([]);
  const data3 = {
    labels: yAxisData,
    datasets: graphData,
  };
  useEffect(() => {
    const yAxisDataArray = [];
    const inputMoment = dayjs(createdTime, "YYYY-MM-DD");
    for (let i = 4; i >= 0; i--) {
      const date = inputMoment.clone().subtract(i, "days").format("MMM DD");
      yAxisDataArray.push(date);
    }
    setYAxisData(yAxisDataArray);
  }, [createdTime, campaignMetrics]);

  return (
    <Row className="mt-3 w-100">
      <Col lg={15} xs={24} className="p-4 d-flex flex-column hs-bg-off-white justify-content-center">
        <h5 style={{ fontWeight: 800 }}>Delivery Errors:</h5>
        <Line className="align-self-center" data={data3} options={options} />
      </Col>
      <Col
        lg={{
          span: 8,
          offset: 1,
        }}
        xs={24}
        className="error-codes"
      >
        <div className="errorCodes p-3">
          <h6 className="p-2">Error Codes</h6>
          <div className="errorCodesListContainer">
            <List
              dataSource={graphData}
              className="error-codes-list"
              renderItem={(obj, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    className="d-flex align-items-center"
                    avatar={
                      <div
                        className="colorDefine"
                        style={{
                          backgroundColor: obj.borderColor,
                          height: 20,
                          width: 20,
                        }}
                      />
                    }
                    title={`${obj?.code} : ${obj?.message} (${obj?.count < 999999 ? `${obj?.count}` : `999999+`})`}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
});

const CampaignErrors = React.memo((props) => {
  const { handleScrollApi, tableData, appProperties } = props;
  return (
    <Row className="pt-3 bg-white w-100">
      <InfiniteVirtualTable
        noDataOverlayIcon={<div className="staticIconsSprite rocketWithAirIcon"></div>}
        appProperties={appProperties}
        columns={columns}
        fetchFn={handleScrollApi}
        data={tableData ?? []}
      />
    </Row>
  );
});
export default CampaignAnalytics;
function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  let colorObj = {
    color: color,
    fillArea: color + "1A",
  };
  return colorObj;
}
