import { Select as AntdSelect, Empty, Form, Tag } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import LabelAndValue from "../../form/LabelAndValue";
import Input from "../input/Input";
import { capitalizeFirstLetter } from "../../../../pages/utils/commonUtils";
const { Option } = AntdSelect;

function Select(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [hasError, setHasError] = useState(false);
  const {
    options,
    customProps,
    listEndComponent,
    label,
    searchPlaceHolder = customProps?.searchBoxPlaceHolder ?? "Search",
    placeholder = "Select",
    name,
    required,
    defaultValue,
    popupClassName = "customSelectPopup",
    groupBy,
    open,
    popupContainerId,
    dropdownRender = (menu) => {
      return (
        <div className="px-1 z-3">
          <div className="d-flex mt-2 mb-1">
            <Input
              variant="borderless"
              placeholder={searchPlaceHolder}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="selectBoxSearchInput"
            />
          </div>
          <div className="dropDownList">{menu}</div>
          <div>{listEndComponent}</div>
        </div>
      );
    },
    formItemRules = required
      ? [
          {
            required: true,
            validator: (_, value) => {
              if (!value && !customProps?.value) {
                setHasError(true);
                return Promise.reject(new Error("Required"));
              } else {
                setHasError(false);
                return Promise.resolve();
              }
            },
          },
        ]
      : [],
    handleDropDownVisibleChange = () => {},
    onMouseDown,
    showOnlyPopup,
    selectSource,
    loading = false,
  } = props;

  customProps.suffixIcon = customProps.suffixIcon ?? <div className="actionIconsSprite fromNumberDropDownIcon" />;

  const handleBeforeDropDownVisibleChange = (visible) => {
    if (!visible) {
      setSearchTerm("");
    }
    handleDropDownVisibleChange(visible);
  };

  const filterFromString = (stringToBeFiltered, searchTerm) => {
    if (typeof stringToBeFiltered === "string" && typeof searchTerm === "string")
      return stringToBeFiltered?.toLowerCase().includes(searchTerm?.toLowerCase());
  };

  const filteredOptions = useMemo(() => {
    return searchTerm
      ? options?.filter((option) => {
          let stringToFilter = "";
          if (typeof option?.label === "string") {
            stringToFilter = option?.labelText ?? option?.label;
          } else {
            stringToFilter = option?.labelText ?? option?.label?.props?.children;
          }
          return filterFromString(stringToFilter, searchTerm);
        })
      : options;
  }, [options, searchTerm]);

  const mapOptions = useCallback((listOfOptions) => {
    if (!listOfOptions) return [];

    if (!groupBy) {
      return listOfOptions.map((option) =>
        typeof option === "string"
          ? { label: <div className="truncate-text">{capitalizeFirstLetter(option)}</div>, value: option }
          : { label: <div className="truncate-text">{option?.label}</div>, value: option?.value }
      );
    }

    const groupedItems = {};

    // Group items by category
    listOfOptions.forEach((option) => {
      const groupByKey = option[groupBy] ?? "hellosend";
      if (!groupedItems[groupByKey]) {
        groupedItems[groupByKey] = [];
      }
      groupedItems[groupByKey].push(option);
    });

    // Create the final list in the desired format
    const groupedOptions = Object.keys(groupedItems).map((category) => ({
      label: <Tag className="select-group-tag">{category}</Tag>,
      title: category,
      options: groupedItems[category],
    }));
    return groupedOptions;
  }, []);

  return (
    <LabelAndValue showOnlyPopup={showOnlyPopup} label={label} hasError={hasError}>
      <Form.Item name={name} rules={formItemRules} className="mb-0">
        <AntdSelect
          {...(customProps ?? {})}
          suffixIcon={showOnlyPopup ? <></> : loading ? <div className="actionIconsSprite dropDownLoading" /> : customProps?.suffixIcon}
          getPopupContainer={() => document.getElementById(popupContainerId)}
          open={open}
          defaultValue={defaultValue}
          fieldNames={name}
          options={mapOptions(filteredOptions)}
          popupMatchSelectWidth={true}
          className={`${
            showOnlyPopup
              ? "hs-select-show-only-popup"
              : `h-50 hs-fs-14 hs-placeholder-text ${customProps?.className ?? ""}  ${hasError ? "hs-input-has-error" : ""} hs-input-box`
          }`}
          filterOption={false}
          popupClassName={popupClassName}
          dropdownRender={dropdownRender}
          onDropdownVisibleChange={handleBeforeDropDownVisibleChange}
          placeholder={placeholder}
          onMouseDown={onMouseDown}
          notFoundContent={
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 60,
              }}
              description={<span>No {selectSource || "options"}!</span>}
            ></Empty>
          }
        ></AntdSelect>
      </Form.Item>
    </LabelAndValue>
  );
}

export default Select;
