import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Dropdown, Input, Menu, Modal, Row, Table } from "antd";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "../context/AppContext";
import "./../assets/css/automationMessages.css";
import { successNotification } from "./utils/CommonNotifications";

import useHttp from "../hooks/useHttp";
import AddOrEditAutomationComponent from "./automation/AddOrEditAutomationComponent";
import {
  CommonLoadingV2,
  CommonSpinLoading,
  EmptyListView,
  NoChannelPresent,
  handleAddAutomation,
  listAllAutomationRecords,
} from "./utils/CommonVessels";

import { Typography } from "antd";
import VideoComponent from "../components/custom/video/VideoComponent";

const { Text } = Typography;

function AutomatedMessages() {
  const [appProperties] = useContext(AppContext);
  const [tableData, setTableData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageAreaLoading, setMessageAreaLoading] = useState(false);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [savedNumbersList, setSavedNumbersList] = useState(null);
  const [openAutomationWebhookForm, setOpenAutomationWebhookForm] = useState(false);
  const [valueObj, setValueObj] = useState({});
  const { fetchData } = useHttp();
  const method = useRef(null);
  const friendlyNameRef = useRef(null);
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [webhookId, setWebhookId] = useState("");
  const [doNotShowEditConfigurationMessage, setDoNotShowEditConfigurationMessage] = useState(true);
  const [automationHelpLink, setAutomationHelpLink] = useState("");
  const [record, setRecord] = useState(null);

  const handleMenuClick = (e, record) => {
    setVisibleDropdown(visibleDropdown === record.eventId ? null : record.eventId);
  };
  console.log("valueObjvalueObjvalueObjvalueObj", valueObj);
  const menu = (record) => <AutomatedMessageDelete record={record} />;

  const AutomatedMessageDelete = (props) => {
    const [open, setOpen] = useState(false);
    let { record } = props;
    record.fieldApiName = typeof record.fieldApiName === "string" ? JSON.parse(record.fieldApiName) : record.fieldApiName;
    const handleDeleteClick = () => {
      setOpen(true);
      setRecord(record);
      setVisibleDropdown(null); // Close the dropdown when opening the modal
    };
    return (
      <>
        <Menu>
          <Menu.Item
            key="editTemplate"
            onClick={() => {
              method.current = "edit";
              setOpenAutomationWebhookForm(true);
              setValueObj(record);
            }}
          >
            <div className="actionIconsSprite editTemplate-icon">
              <span className="ps-4" style={{ fontSize: "12px" }}>
                Edit
              </span>
            </div>
          </Menu.Item>
          <Menu.Item key="deletetemplate" onClick={() => handleDeleteClick(record)}>
            <div className="actionIconsSprite deleteTemplate-icon">
              <span className="ps-4" style={{ fontSize: "12px" }}>
                Delete
              </span>
            </div>
          </Menu.Item>
        </Menu>
        <DeleteComponent setTableData={setTableData} record={record} appProperties={appProperties} setModalOpen={setOpen} modalOpen={open} />
      </>
    );
  };
  const columns = [
    {
      title: "Webhook URL",
      key: "eventUrl",
      dataIndex: "eventUrl",
      width: "14%",
    },
    {
      title: "Friendly Name",
      dataIndex: "friendlyName",
      key: "friendlyName",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "Module",
      dataIndex: "module",
      key: "module",
      width: "9%",
    },
    {
      title: "Created Time",
      key: "createdTime",
      dataIndex: "createdTime",
      width: "16%",
    },
    {
      key: "editDeleteSettings",
      render: (record) => (
        <Dropdown
          rootClassName="editDeleteSettingDropdown"
          overlay={menu(record)}
          trigger={["click"]}
          visible={visibleDropdown === record.eventId}
          onVisibleChange={() => handleMenuClick(null, record)}
        >
          <div className="actionIconsSprite  editDeleteSettingsIcon"></div>
        </Dropdown>
      ),
      width: "5%",
    },
  ];

  useEffect(() => {
    setDoNotShowEditConfigurationMessage(appProperties?.controller?.service !== "Zoho CRM");
  }, [appProperties]);
  useEffect(() => {
    setAutomationHelpLink(appProperties?.controller?.automationPageHelpLink);
  }, [appProperties?.controller]);

  useEffect(() => {
    let integId = appProperties?.randomIntegId;
    if (appProperties.serviceInstalled && integId) {
      setLoading(true);
      fetchData(`omessage/${integId}/webhookNotifications?type=url`, "GET", null, appProperties)
        .then(function (response) {
          setLoading(false);
          if (response) {
            let data = JSON.parse(response);
            let dataList = data?.data?.webhookNotifications;
            dataList = listAllAutomationRecords(dataList);
            setTableData(dataList);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [appProperties.serviceInstalled, fetchData, appProperties]);

  useEffect(() => {
    if (method.current === "edit") {
      setVisibleDropdown(null);
      let integId = appProperties.randomIntegId;
      if (integId && valueObj?.templateId) {
        fetchData(`omessage/${integId}/template/${valueObj?.templateId}/preview`, "GET", null, appProperties).then(function (response) {
          if (response) {
            const responseData = JSON.parse(response);
            if (responseData?.data?.data) {
              setValueObj((prev) => ({
                ...prev,
                message: responseData?.data?.data,
              }));
            }
          }
        });
      }
    }
  }, [method, openAutomationWebhookForm, savedNumbersList, valueObj?.templateId, appProperties, fetchData]);
  useEffect(() => {
    if (openAutomationWebhookForm) {
      if (method.current !== "edit") {
        const phoneObj = savedNumbersList?.find((obj) => obj?.defaultNumber === 1) || savedNumbersList?.[0];
        setValueObj((prev) => ({
          ...prev,
          phoneNum: phoneObj?.phoneNumber,
          integId: phoneObj?.integId,
          templateId: "",
        }));
      }
      friendlyNameRef.current?.focus();
    }
  }, [openAutomationWebhookForm, savedNumbersList]);
  const addAutomation = (mediaFiles) => {
    handleAddAutomation(
      valueObj,
      setAddBtnLoading,
      method,
      setOpenAutomationWebhookForm,
      fetchData,
      setValueObj,
      setTableData,
      appProperties,
      setWebhookUrl,
      setWebhookId
    );
  };

  const doOnDrawerOpenChange = (drawerOpenStatus) => {
    if (!drawerOpenStatus) {
      setFileList([]);
    }
  };

  const videoObj = useMemo(() => appProperties?.controller?.automationPageHelpVideo, [appProperties?.controller?.automationPageHelpVideo]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between m-3">
        <div className="mt-2 d-none d-lg-flex d-flex align-items-center">
          <div className="actionIconsSprite authInfo-icon" style={{ cursor: "default" }}></div>
          <div className="ms-2">
            Configure webhook to Automate SMS / WhatsApp messages based on any Workflow rules, to know more{" "}
            <a href={automationHelpLink} target="_blank" rel="noreferrer" className="custom-anchor">
              Click here
            </a>
          </div>
        </div>
        <Button
          className="addTempBtn ms-auto"
          onClick={() => {
            method.current = "add";
            setValueObj({});
            setOpenAutomationWebhookForm(true);
          }}
          icon={<div className="actionIconsSprite plusIconWhite" />}
        >
          Create Webhook
        </Button>
      </div>

      <div className="mt-3">
        {appProperties.serviceInstalled ? (
          <>
            {!loading ? (
              <Row className="m-2">
                <Col xs={24} lg={15} className="mx-1 mx-lg-3">
                  <Table
                    className="automationTable"
                    dataSource={tableData}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 700, y: 690 }}
                    style={{ cursor: "text" }}
                    locale={{
                      emptyText: (
                        <EmptyListView
                          icon={"actionIconsSpriteForSideBar emptyAutomationListView display-inline-block"}
                          tagLineOne={"Create Webhook now!"}
                          tagLineTwo={"Streamline Automation with Webhooks"}
                          tagHeight={"50vh"}
                        />
                      ),
                    }}
                  />
                </Col>
                <Col xs={24} lg={8} className="p-2">
                  <VideoComponent videoObj={videoObj} search />
                </Col>
              </Row>
            ) : (
              <CommonSpinLoading />
            )}
            <Drawer
              open={openAutomationWebhookForm}
              closable
              title={method.current === "edit" ? "Edit Configuration" : "Create Webhook"}
              width={500}
              onClose={() => {
                setOpenAutomationWebhookForm(false);
                setValueObj({});
              }}
              afterOpenChange={doOnDrawerOpenChange}
              className="automation-drawer"
              footer={
                <>
                  {method.current === "edit" && (
                    <>
                      <div className="p-3 w-100 trialAccount ">
                        <ExclamationCircleOutlined style={{ fontSize: 20, color: "#FF9800" }} />
                        <div>
                          After configuration editing, the webhook URL remains unchanged, no need to update the{" "}
                          {appProperties?.leftServiceDisplayName} automation
                        </div>
                      </div>
                    </>
                  )}
                  <div className="d-flex align-items-center justify-content-end">
                    <Button loading={addBtnLoading} className="addTempBtn w-100 m-3 justify-content-center hs-fs-16" onClick={addAutomation}>
                      {method.current === "edit" ? "Edit Configuration" : "Create Webhook URL"}
                    </Button>
                  </div>
                </>
              }
            >
              <AddOrEditAutomationComponent
                valueObj={valueObj}
                friendlyNameRef={friendlyNameRef}
                setValueObj={setValueObj}
                method={method}
                savedNumbersList={savedNumbersList}
                setSavedNumbersList={setSavedNumbersList}
                drawerOpen={openAutomationWebhookForm}
                setFileList={setFileList}
                setMessageAreaLoading={setMessageAreaLoading}
                messageAreaLoading={messageAreaLoading}
                setWebhookUrl={setWebhookUrl}
                setWebhookId={setWebhookId}
                fileList={fileList}
                doNotShowEditConfigurationMessage={doNotShowEditConfigurationMessage}
              />
            </Drawer>
          </>
        ) : (
          <NoChannelPresent />
        )}
      </div>
    </>
  );
}
export default AutomatedMessages;

const DeleteComponent = ({ record, setTableData, appProperties, setModalOpen, modalOpen }) => {
  const [loading, setLoading] = useState(false);
  const [outlineColor, setOutlineColor] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const { fetchData } = useHttp();
  const inputRef = useRef(null);

  useEffect(() => {
    if (modalOpen) {
      inputRef.current.focus();
    }
  }, [modalOpen]);

  const closeModal = () => {
    setModalOpen(false);
    setOutlineColor(false);
    setDeleteText("");
  };

  const handleDeleteAutomation = () => {
    if (deleteText === "delete" && record?.integId && record?.eventId) {
      setLoading(true);
      fetchData(`omessage/${record?.integId}/webhookNotification/${record?.eventId}?type=automation`, "DELETE", record, appProperties).then(
        (response) => {
          if (response) {
            successNotification("Webhook deleted successfully. Consider adding a new one to automate messages.");
            response = JSON.parse(response);
            const webhookNotificationEntity = response?.data?.data;
            setTableData((prev) => prev.filter((obj) => obj?.eventId !== webhookNotificationEntity?.eventId));
            closeModal();
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      );
    } else if (deleteText !== "delete") {
      setOutlineColor(true);
      setDeleteText("");
    }
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onCancel={closeModal}
        width={600}
        centered
        footer={
          <div className="d-flex align-items-center justify-content-between mt-3 m-2">
            <Button className="w-100 me-2 h-45 hs-fs-16 commonGhostButton" onClick={closeModal}>
              No, cancel
            </Button>
            <Button className="w-100 ms-2 h-45 addTempBtn hs-fs-16" type="primary" onClick={handleDeleteAutomation}>
              Yes, confirm
            </Button>
            {loading ? <CommonLoadingV2 /> : null}
          </div>
        }
      >
        <Row>
          <Col span={3}>
            <div className="phoneLogoSprites delete-icon mt-4"></div>
          </Col>
          <Col span={21} className="mt-2">
            <div>
              <div style={{ fontWeight: 600, fontSize: 18, color: "#030229" }}>Are you sure you want to disconnect?</div>
              <div className="m-1" style={{ color: "rgba(3, 2, 41, 0.5)" }}>
                This webhook URL may be connected to Automation rules, which could result in your customers not receiving important messages or
                updates. We won't recommend deleting the webhook URL. Please type 'delete' to proceed.
              </div>
              <Input
                ref={inputRef}
                placeholder="delete"
                className="rounded hs-bg-off-white m-2 h-45"
                variant="borderless"
                value={deleteText}
                style={{
                  width: 120,
                  outline: outlineColor ? "1.5px solid red" : "1.5px solid rgba(96, 91, 255, 1)",
                }}
                onChange={(e) => {
                  setDeleteText(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
