import { leftServiceId, urlParams } from "../constants/AppConstants";
import useHttp from "./useHttp";

export default function useServices() {
  var rightServiceId = urlParams.get("rightServiceId");

  var selectedIds = urlParams.get("selectedIds");
  var resource = urlParams.get("resource");

  const { fetchData } = useHttp();

  const getAllTextSMSServices = (appProperties) => {
    return new Promise((resolve, reject) => {
      const leftServiceId = appProperties?.leftServiceId || new URLSearchParams(window.location.search).get("leftServiceId");
      fetchData(`omessage/apps?serviceId=${leftServiceId}`, "GET", null, appProperties)
        .then((data) => {
          let response = "";
          if (data) {
            response = JSON.parse(data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchInstalledServices = (appProperties) => {
    let path = window.location.pathname;
    var customWorkflow = "";
    if (path === "/zoho-crm/webhook") {
      customWorkflow = "custom-workflow";
    }
    var urlParams = new URLSearchParams(window.location.search);
    var leftServiceId = urlParams.get("leftServiceId");
    var companyId = urlParams.get("companyId");
    var userId = urlParams.get("userId");
    var userId = appProperties?.userId ?? userId;
    var companyId = appProperties.companyId ?? companyId;
    return new Promise((resolve, reject) => {
      fetchData(
        `integrations?leftServiceId=${leftServiceId}&rightServiceId=${rightServiceId}&selectedIds=${selectedIds}&resource=${resource}&companyId=${companyId}&userId=${userId}&action=${customWorkflow}`,
        "GET",
        null,
        appProperties
      )
        .then((response) => {
          let responseData = "";
          if (response) {
            responseData = JSON.parse(response);
          }
          resolve(responseData);
        })
        .catch((error) => {
          console.log("fetchInstalledServices >>>>>>>>>>>>>>>>", error);
        });
    });
  };

  return {
    getAllTextSMSServices,
    fetchInstalledServices,
  };
}
